


















import {Component, Ref, Vue} from "vue-property-decorator";
import axios from 'axios';

@Component
export default class Synchronize extends Vue {
    @Ref('loadableButton')
    loadableButton: any;

    synchronizeCustomer() {
        this.$vs.loading({
            background: 'primary',
            color: '#fff',
            container: "#btnSaveCustomer",
            scale: 0.45
        });
        this.$vs.loading.close();
        const response = axios.get(`${process.env.VUE_APP_GATEWAY_URL}customer/api/v1/customers/synchronize`);
        response.then((rs: any) => {
            this.$vs.loading.close('#btnSaveCustomer > .con-vs-loading')
            console.log(rs);
        }).catch(e =>  this.$vs.loading.close('#btnSaveCustomer > .con-vs-loading'));

    }

    synchronizeCategory() {
        this.$vs.loading({
            background: 'primary',
            color: '#fff',
            container: "#btnSaveCategory",
            scale: 0.45
        });
        this.$vs.loading.close();
        const response = axios.get(`${process.env.VUE_APP_GATEWAY_URL}core/api/v1/categories/synchronize`);
        response.then((rs: any) => {
            this.$vs.loading.close('#btnSaveCategory > .con-vs-loading')
            console.log(rs);
        }).catch(e =>  this.$vs.loading.close('#btnSaveCategory > .con-vs-loading'));

    }

    synchronizeContact() {
        this.$vs.loading({
            background: 'primary',
            color: '#fff',
            container: "#btnSynchronizeContact",
            scale: 0.45
        });
        this.$vs.loading.close();
        const response = axios.post(`${process.env.VUE_APP_GATEWAY_URL}customer/api/v1/customers/resynchronize-contact`);
        response.then((rs: any) => {
            this.$vs.loading.close('#btnSynchronizeContact > .con-vs-loading')
            console.log(rs);
        }).catch(e =>  this.$vs.loading.close('#btnSynchronizeContact > .con-vs-loading'));

    }
}
